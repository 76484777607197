import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useFireStoreSubscriptionPassingAccountId,
  useFireStoreSubscriptionPassingAccountId_1deep,
} from "../Hooks/useFireStoreSubscription";
import {
  FirestorePathRoute,
  NotificationDeviceCallRequest,
  NotificationLockdownPlan,
  NotificationLockdownPlanModel,
  NotificationModel,
} from "../../Models/notifications";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import DoorVideo from "../VideoDoorWebRTC/DoorVideo";
import { toast, Bounce } from "react-toastify";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import * as actions from "../../../../store/actions";
import { useDispatch } from "react-redux";
import {
  SecondsToDiscardDoorRing,
  SecondsToDiscardNFCTagRing,
} from "../../Constants/validationValues";
import { getLockdownPlanInfo } from "../../API/apiWorkflow";
import { LockdownPlanInfo } from "../../Models/workflow";
import { getDeviceIdentificationWorkflows } from "../../API/apiDevices";
import { hasTheCredentialTheIdentificationWorkflow } from "../HelperCode";
import { CredentialType } from "../../Models/devices";

interface OpenVideo {
  id: string;
  open: boolean;
  name: string;
}
interface Props {
  accountId: number;
  totalDevicesConnectedWebRTC: string[];
  hasPermissionAccountRead: boolean;
  didCallAPILockdownInfo: boolean;
  userId: string;
  audioNotification: HTMLAudioElement | undefined;
}
const NotificationGeneralApp = ({
  accountId,
  totalDevicesConnectedWebRTC,
  hasPermissionAccountRead,
  didCallAPILockdownInfo,
  userId,
  audioNotification,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentTime = useRef(moment().valueOf());
  const refAudioNotificationDoorbell = useRef<HTMLAudioElement | undefined>();
  const refIsMuted = useRef<boolean>(false);

  const handleMute = () => {
    const time = moment();
    let expiry = new Date();
    if (refIsMuted.current) {
      expiry = time.subtract(1, "days").toDate();
      if (refAudioNotificationDoorbell.current) {
        refAudioNotificationDoorbell.current.volume = 1;
      }
    } else {
      expiry = time.add(30, "years").toDate();
      if (refAudioNotificationDoorbell.current) {
        refAudioNotificationDoorbell.current.volume = 0;
      }
    }

    document.cookie = `userId_${userId}=true;expires=${expiry.toUTCString()};path=/`;

    refIsMuted.current = !refIsMuted.current;
  };

  const readCookie = () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(`userId_${userId}`)) {
        return true;
      }
    }
    return false;
  };

  const playAudioNotificationDoorbell = () => {
    if (refAudioNotificationDoorbell.current) {
      // if (isHasUserId) {
      // refAudioNotificationDoorbell.current.volume = 0;
      // } else {
      refAudioNotificationDoorbell.current.volume = 1;
      // }
      refAudioNotificationDoorbell.current.muted = false;
      refAudioNotificationDoorbell.current.play().catch((error) => {
        console.error("Error playing the sound:", error);
      });
    }
  };

  const pauseAudioNotificationDoorbell = () => {
    if (refAudioNotificationDoorbell.current) {
      refAudioNotificationDoorbell.current.pause();
    }
  };

  useEffect(() => {
    if (audioNotification && !refAudioNotificationDoorbell.current) {
      refAudioNotificationDoorbell.current = audioNotification;
    }
  }, [audioNotification]);

  //////////////////////////////////
  useEffect(() => {
    dispatch(actions.requestPermissionPushNotification());
  }, []);
  /////////////////////////////////////

  /////////////////////////////////
  const totalDevicesConnectedWebRTCRef = useRef(totalDevicesConnectedWebRTC);
  useEffect(() => {
    totalDevicesConnectedWebRTCRef.current = totalDevicesConnectedWebRTC;
  });
  /////////////////////////////////
  const refUpdatedByEndpointLockdownPlanStatus = useRef(false);
  useEffect(() => {
    const load = async () => {
      await getLockdownPlanInfo()
        .then((response) => {
          try {
            if (!response.data.hasErrors) {
              const temp: LockdownPlanInfo = response.data.payload;
              // dispatch(actions.isLockdownPlanSetUp(temp.isLockdownSetup));
              dispatch(
                actions.updateStatusGeneralLockdown(
                  temp.isLockdownActive,
                  temp.startTimeOfLockdown,
                  temp.lockdownPlanDuration,
                  temp.anyLockdownWorkflows
                )
              );
              refUpdatedByEndpointLockdownPlanStatus.current = true;
            }
          } catch (error) {
            //
          }
        })
        .catch((error: any) => {
          // manage in the interceptor
        })
        .finally(() => {
          //
        });
    };
    if (hasPermissionAccountRead && !didCallAPILockdownInfo) {
      load();
    }
  }, [hasPermissionAccountRead, didCallAPILockdownInfo]);
  //////////////Door Ring Notifications///////////////////
  const [openVideo, setOpenVideo] = useState<OpenVideo>({
    id: "",
    open: false,
    name: "",
  });
  const [hasWebAccess, setHasWebAccess] = useState(false);
  const checkForWebCredential = async (serialNumber: string, name: string) => {
    try {
      const response = await getDeviceIdentificationWorkflows(serialNumber);
      if (!response.data.hasErrors) {
        const temp: number[][] = response.data.payload;
        const hasweb = hasTheCredentialTheIdentificationWorkflow(temp, CredentialType.Web);
        setHasWebAccess(hasweb);
      }
    } catch (error) {
      //
    } finally {
      setOpenVideo({
        id: serialNumber,
        open: true,
        name: name,
      });
    }
  };
  /////////////////////////////////

  ////////////////////////////////
  useFireStoreSubscriptionPassingAccountId(
    accountId ? accountId.toString() : "",
    FirestorePathRoute.Devices.Index,
    FirestorePathRoute.Devices.DoorBellRing,
    (data: NotificationModel[] | NotificationModel) =>
      newDoorBellRingNotification(data as NotificationModel[])
  );
  const newDoorBellRingNotification = (data: NotificationModel[]) => {
    data.forEach((noti) => {
      const diff = moment.utc().diff(moment(noti.Data.Timestamp).utc(), "seconds");
      if (diff < SecondsToDiscardDoorRing && diff >= 0) {
        let showButton = false;
        if (
          totalDevicesConnectedWebRTCRef.current.findIndex(
            (serial) => serial === noti.Data.SerialNumber
          ) === -1
        ) {
          showButton = true;
        }
        toast.info(
          ({ closeToast, toastProps }) => {
            return (
              <div
                style={{
                  position: "relative",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: showButton ? "space-around" : "flex-start",
                    alignItems: "center",
                    marginLeft: !showButton ? "8px" : "0px",
                  }}
                >
                  <Typography variant="body2" display="inline">
                    {t("DoorRinging") + ": " + noti.Data.DeviceName}
                  </Typography>
                  {showButton ? (
                    <>
                      <Button
                        style={{ marginRight: "1rem" }}
                        size="small"
                        variant="contained"
                        onClick={() => {
                          dispatch(actions.removedFireStoreDoorRingSerial(noti.Data.SerialNumber));
                          closeToast && closeToast();
                          checkForWebCredential(noti.Data.SerialNumber, noti.Data.DeviceName);
                        }}
                      >
                        {t("Check")}
                      </Button>
                      {/* <Button size="small" variant="contained" onClick={handleMute}>
                        {refIsMuted.current ? "Unmute" : "Mute"}
                      </Button> */}
                    </>
                  ) : null}
                </div>
                <div style={{ position: "absolute", right: -5, top: -5 }}>
                  <IconButton
                    size="small"
                    type="reset"
                    onClick={() => {
                      dispatch(actions.removedFireStoreDoorRingSerial(noti.Data.SerialNumber));
                      closeToast && closeToast();
                    }}
                  >
                    <CloseIcon
                      fontSize={"small"}
                      style={{ height: "16px", width: "14px", color: "#000", opacity: 0.3 }}
                    />
                  </IconButton>
                </div>
              </div>
            );
          },
          {
            position: toast.POSITION.BOTTOM_LEFT,
            toastId: "DoorRing" + noti.Data.SerialNumber,
            autoClose: SecondsToDiscardDoorRing * 1000,
            closeButton: false,
            // className: styles.NotificationGeneralApp_border_glow,
            closeOnClick: false,
            transition: Bounce,
            hideProgressBar: false,
            pauseOnFocusLoss: false,
            onOpen: () => {
              // const value = readCookie();
              playAudioNotificationDoorbell();
            },
            onClose: () => {
              pauseAudioNotificationDoorbell();
            },
          }
        );
        dispatch(actions.receivedSerialNumberDoorRingNotification(noti.Data.SerialNumber));
      }
    });
  };
  ////////////////////////////////
  useFireStoreSubscriptionPassingAccountId(
    accountId ? accountId.toString() : "",
    FirestorePathRoute.Users.Index,
    FirestorePathRoute.Users.DeviceCallRequest,
    (data: NotificationModel[] | NotificationModel) =>
      newDoorBellRingNFCTagNotification(data as NotificationModel),
    userId
  );
  const newDoorBellRingNFCTagNotification = (data: NotificationModel) => {
    if (data.Code === NotificationDeviceCallRequest.NotificationDeviceCallRequest) {
      const timestamp = data.Data.timestamp;
      const serialNumber = data.Data.serialNumber;
      const diff = moment.utc().diff(moment(timestamp).utc(), "seconds");
      if (diff < SecondsToDiscardNFCTagRing && diff >= 0) {
        let showButton = false;
        if (
          totalDevicesConnectedWebRTCRef.current.findIndex((serial) => serial === serialNumber) ===
          -1
        ) {
          showButton = true;
        }
        toast.info(
          ({ closeToast, toastProps }) => {
            return (
              <div
                style={{
                  position: "relative",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: showButton ? "space-around" : "flex-start",
                    alignItems: "center",
                    marginLeft: !showButton ? "8px" : "0px",
                  }}
                >
                  <Typography variant="body2" display="inline">
                    {t("DoorRinging")}
                  </Typography>
                  {showButton ? (
                    <Button
                      style={{ marginRight: "1rem" }}
                      size="small"
                      variant="contained"
                      onClick={() => {
                        dispatch(actions.removedFireStoreDoorRingSerial(serialNumber));
                        closeToast && closeToast();
                        checkForWebCredential(serialNumber, t("Doorbell"));
                      }}
                    >
                      {t("Check")}
                    </Button>
                  ) : null}
                </div>
                <div style={{ position: "absolute", right: -5, top: -5 }}>
                  <IconButton
                    size="small"
                    type="reset"
                    onClick={() => {
                      dispatch(actions.removedFireStoreDoorRingSerial(serialNumber));
                      closeToast && closeToast();
                    }}
                  >
                    <CloseIcon
                      fontSize={"small"}
                      style={{ height: "16px", width: "14px", color: "#000", opacity: 0.3 }}
                    />
                  </IconButton>
                </div>
              </div>
            );
          },
          {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "DoorRingNFCTag" + serialNumber,
            autoClose: SecondsToDiscardNFCTagRing * 1000,
            closeButton: false,
            // className: styles.NotificationGeneralApp_border_glow,
            closeOnClick: false,
            transition: Bounce,
            hideProgressBar: false,
          }
        );
      }
    }
  };
  /////General Lockdown Notification//////////////////////////
  useFireStoreSubscriptionPassingAccountId_1deep(
    accountId ? accountId.toString() : "",
    FirestorePathRoute.LockdownPlan.Index,
    (payload: NotificationModel) => newLockDownPlanNotification(payload as NotificationModel)
  );
  const newLockDownPlanNotification = (payload: NotificationModel) => {
    const code: string = payload.Code;
    const data: NotificationLockdownPlanModel = payload.Data;
    if (code === NotificationLockdownPlan.NotificationLockdownPlan && hasPermissionAccountRead) {
      if (refUpdatedByEndpointLockdownPlanStatus.current) {
        //this is to avoid double update
        dispatch(
          actions.updateStatusGeneralLockdown(
            data.isLockdownActive,
            data.startTimeOfLockdown,
            data.lockdownPlanDuration,
            data.anyLockdownWorkflows
          )
        );
      }
    }
  };
  ///////////////////////////////
  return (
    <>
      {openVideo.open ? (
        <DoorVideo
          hasWebCredential={hasWebAccess}
          openVideo={openVideo.open}
          serial={openVideo.id}
          name={openVideo.name}
          onClose={() => {
            setOpenVideo({
              id: "",
              open: false,
              name: "",
            });
            setHasWebAccess(false);
          }}
        />
      ) : null}
    </>
  );
};
export default NotificationGeneralApp;
